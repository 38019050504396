import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Event as EventType } from "../types";
import { API_URL, LOCAL_URL } from "../constants";
import { Calendar, MapPin, Smartphone } from "lucide-react";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from "../constants";

export default function Event() {
  const { id } = useParams();
  const [event, setEvent] = useState<EventType | null>(null);
  const deepLink = window.location.pathname.substring(1);

  const fetchEvent = async () => {
    try {
      const api_url =
        process.env.NODE_ENV === "production" ? API_URL : LOCAL_URL;
      const response = await fetch(`${api_url}/website/event/${id}`);
      const data = await response.json();
      setEvent(data);
    } catch (error) {
      console.error("Error fetching event:", error);
    }
  };

  useEffect(() => {
    fetchEvent();
  }, [id]);

  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const appScheme = isIOS
      ? "citymoshi://"
      : `intent://link/${deepLink}#Intent;scheme=citymoshi;package=com.citymoshi.app;end`;
    window.location.href = isIOS ? `${appScheme}link/${deepLink}` : appScheme;
  }, [deepLink]);

  if (!event) {
    return (
      <div className="min-h-screen bg-[#0A2436] flex items-center justify-center">
        <div className="text-white text-lg">Loading...</div>
      </div>
    );
  }

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const formatTime = (dateString: string) => {
    return new Date(dateString).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className="min-h-screen bg-[#0A2436] pb-8">
      {/* Download Banner */}
      <div className="mb-4">
        <div className="max-w-7xl mx-auto px-4 py-5 flex flex-col sm:flex-row items-center justify-between gap-3">
          <div className="flex items-center gap-1">
            <img
              src="/images/logo/main-logo.png"
              alt="Citymoshi Logo"
              className="w-8 h-8"
            />
            <h1 className="font-medium text-white font-inter text-xl">
              CityMoshi
            </h1>
          </div>
          <div className="flex items-center gap-3">
            <p className="text-white/90">
              Get the best experience with our mobile app!
            </p>
            <button
              onClick={() => window.open(APP_STORE_LINK, "_blank")}
              className="flex items-center gap-2 px-4 py-1.5 bg-white rounded-lg text-sm font-medium text-[#A6425C] hover:bg-gray-100 transition-colors"
            >
              <FaApple className="h-4 w-4" />
              iOS App
            </button>
            <button
              onClick={() => window.open(GOOGLE_PLAY_LINK, "_blank")}
              className="flex items-center gap-2 px-4 py-1.5 bg-white rounded-lg text-sm font-medium text-[#A6425C] hover:bg-gray-100 transition-colors"
            >
              <FaGooglePlay className="h-4 w-4" />
              Android App
            </button>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Column - Images and People */}
          <div className="space-y-6">
            {/* Main Image */}
            <div className="relative rounded-xl overflow-hidden">
              <img
                src={event.image}
                alt={event.name}
                className="w-full aspect-[4/3] object-cover"
              />
              <span className="absolute top-4 left-4 px-3 py-1 bg-[#A6425C] text-white text-sm rounded-full">
                {event.category?.name || "Event"}
              </span>
            </div>

            {/* Guests Section */}
            {event.eventGuests && event.eventGuests.length > 0 && (
              <div className="bg-[#0F3451] rounded-xl p-6">
                <h2 className="text-xl font-bold mb-4 text-white">
                  Featured Guests
                </h2>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                  {event.eventGuests.map(({ guest }) => (
                    <div key={guest.id} className="bg-[#0A2436] p-4 rounded-lg">
                      <img
                        src={guest.image || "/api/placeholder/100/100"}
                        alt={guest.firstName}
                        className="w-full aspect-square object-cover rounded-lg mb-3"
                      />
                      <p className="font-medium text-white">
                        {guest.firstName} {guest.lastName}
                      </p>
                      <p className="text-white/70 text-sm">{guest.role}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Sponsors Section */}
            {event.sponsors && event.sponsors.length > 0 && (
              <div className="bg-[#0F3451] rounded-xl p-6">
                <h2 className="text-xl font-bold mb-6 text-white">Sponsors</h2>
                <div className="grid grid-cols-3 gap-6">
                  {event.sponsors.map((sponsor) => (
                    <div
                      key={sponsor.id}
                      className="bg-[#0A2436] rounded-lg p-6 flex flex-col items-center justify-center hover:bg-[#0D2D44] transition-all group"
                    >
                      <div className="w-full aspect-[3/2] relative mb-4">
                        <img
                          src={sponsor.image || "/api/placeholder/200/150"}
                          alt={sponsor.name}
                          className="w-full h-full object-contain absolute inset-0 group-hover:scale-105 transition-transform"
                        />
                      </div>
                      <p className="text-white font-medium text-center">
                        {sponsor.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Right Column - Event Details */}
          <div className="bg-[#0F3451] rounded-xl p-6">
            {/* Event Header */}
            <div className="mb-6">
              <h1 className="text-3xl font-bold text-white mb-2">
                {event.name}
              </h1>
              {event.subTitle && (
                <p className="text-xl text-white/80 mb-4">{event.subTitle}</p>
              )}
            </div>

            {/* Quick Info */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
              <div className="bg-[#0A2436] p-4 rounded-lg">
                <div className="flex items-center text-white">
                  <Calendar className="h-5 w-5 mr-3 text-[#A6425C]" />
                  <div>
                    <p className="font-medium">Start</p>
                    <p className="text-white/70">
                      {formatDate(event.startDate)}
                    </p>
                    <p className="text-white/70">
                      {formatTime(event.startDate)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-[#0A2436] p-4 rounded-lg">
                <div className="flex items-center text-white">
                  <Calendar className="h-5 w-5 mr-3 text-[#A6425C]" />
                  <div>
                    <p className="font-medium">End</p>
                    <p className="text-white/70">{formatDate(event.endTime)}</p>
                    <p className="text-white/70">{formatTime(event.endTime)}</p>
                  </div>
                </div>
              </div>
              <div className="bg-[#0A2436] p-4 rounded-lg sm:col-span-2">
                <div className="flex items-center text-white">
                  <MapPin className="h-5 w-5 mr-3 text-[#A6425C]" />
                  <div>
                    <p className="font-medium">{event.location?.name}</p>
                    <p className="text-white/70">{event.location?.address}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Description */}
            <div className="mb-8">
              <h2 className="text-xl font-bold mb-4 text-white">Description</h2>
              <p className="text-white/90 leading-relaxed">
                {event.description}
              </p>
            </div>

            {/* Tickets */}
            {event.tickets && event.tickets.length > 0 && (
              <div className="mb-8">
                <h2 className="text-xl font-bold mb-4 text-white">Tickets</h2>
                <div className="space-y-4">
                  <div className="grid grid-cols-3 text-sm text-white/70 px-4">
                    <div>Name</div>
                    <div className="text-center">Currency</div>
                    <div className="text-right">Price</div>
                  </div>
                  {event.tickets.map((ticket, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-4 bg-[#0A2436] rounded-lg"
                    >
                      <div className="grid grid-cols-3 w-full items-center">
                        <p className="font-medium text-white">{ticket.name}</p>
                        <p className="text-white/70 text-center">
                          {ticket.currency}
                        </p>
                        <p className="text-white/70 text-right">
                          {ticket.price}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                {event.ticket_url && (
                  <div className="mt-4">
                    <b className="text-white">Ticket Instructions</b>
                    {event.ticket_info && (
                      <p className="text-white/70 text-sm mt-1">
                        {event.ticket_info}
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}

            {/* Download App Card */}
            <div className="bg-gradient-to-br from-[#A6425C] to-[#8A3549] rounded-xl p-6">
              <div className="text-center">
                <div className="w-16 h-16 mx-auto mb-4 bg-white/10 rounded-full flex items-center justify-center">
                  <Smartphone className="h-8 w-8 text-white" />
                </div>
                <h3 className="text-xl font-bold mb-3 text-white">
                  Download Our App to Join The Chat
                </h3>
                <p className="text-white/90 mb-6">
                  Get exclusive features and real-time updates on your mobile
                  device
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <button
                    onClick={() => window.open(APP_STORE_LINK, "_blank")}
                    className="flex-1 px-6 py-3 bg-white text-[#A6425C] rounded-lg hover:bg-gray-100 transition-colors flex items-center justify-center"
                  >
                    <FaApple className="h-5 w-5 mr-2" />
                    Download for iOS
                  </button>
                  <button
                    onClick={() => window.open(GOOGLE_PLAY_LINK, "_blank")}
                    className="flex-1 px-6 py-3 bg-white text-[#A6425C] rounded-lg hover:bg-gray-100 transition-colors flex items-center justify-center"
                  >
                    <FaGooglePlay className="h-5 w-5 mr-2" />
                    Download for Android
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
