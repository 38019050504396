import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { cn } from "../utils/utils";
import { RiVipCrownFill } from "react-icons/ri";
import { FaCompass, FaSearch, FaComments, FaLock } from "react-icons/fa";

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

interface Feature {
  icon: React.ReactNode;
  title: string;
  content: string;
  image: string;
  keywords: string[];
}

const features: Feature[] = [
  {
    icon: <RiVipCrownFill className="w-5 h-5" />,
    title: "Personalization",
    content:
      "Receive event recommendations that match your preferences and moods.",
    image: "/images/welcome.png",
    keywords: [
      "AI-Driven",
      "User Preferences",
      "Mood Detection",
      "Custom Experiences",
    ],
  },
  {
    icon: <FaCompass className="w-5 h-5" />,
    title: "Explore",
    content: "Discover all events in one easy-to-use app.",
    image: "/images/home.png",
    keywords: [
      "Event Discovery",
      "Intuitive UI",
      "Category Filtering",
      "Location-Based",
    ],
  },
  {
    icon: <FaSearch className="w-5 h-5" />,
    title: "Hassle-Free",
    content: "Get all the event details you need, effortlessly.",
    image: "/images/details.png",
    keywords: [
      "Quick Info",
      "Ticket Purchasing",
      "Venue Details",
      "Event Schedule",
    ],
  },
  {
    icon: <FaComments className="w-5 h-5" />,
    title: "Join Chats",
    content: "Connect with others who share your vibe.",
    image: "/images/chat-screen.png",
    keywords: [
      "Group Chats",
      "Event Discussion",
      "Meet New People",
      "Shared Interests",
    ],
  },
  {
    icon: <FaLock className="w-5 h-5" />,
    title: "Exclusivity",
    content: "Host private events and keep your special moments alive.",
    image: "/images/create-events.png",
    keywords: [
      "Private Events",
      "Invite-Only",
      "Customizable Settings",
      "Memories Archive",
    ],
  },
];

function Features() {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [scrollTriggerInstance, setScrollTriggerInstance] = useState<ScrollTrigger | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const imagesLoadedRef = useRef(0);
  const totalImages = features.length;

  // Preload images
  useEffect(() => {
    const preloadImages = () => {
      features.forEach((feature) => {
        const img = new Image();
        img.src = feature.image;
        img.onload = () => {
          imagesLoadedRef.current += 1;
          if (imagesLoadedRef.current === totalImages) {
            setIsInitialized(true);
          }
        };
      });
    };

    preloadImages();
  }, [totalImages]);

  useEffect(() => {
    if (!containerRef.current || !contentRef.current || !isInitialized) return;

    // Reset scroll position on init
    window.scrollTo(0, 0);

    const scrollDistance = window.innerHeight;
    
    const scrollTrigger = ScrollTrigger.create({
      trigger: containerRef.current,
      start: "top top",
      end: `+=${scrollDistance * (features.length - 1)}`,
      pin: true,
      pinSpacing: true,
      scrub: true,
      onUpdate: (self) => {
        const progress = self.progress;
        const featureIndex = Math.min(
          Math.floor(progress * features.length),
          features.length - 1
        );
        
        // Debounce the state update
        requestAnimationFrame(() => {
          setActiveIndex(featureIndex);
        });
      },
      fastScrollEnd: true, // Optimize for fast scrolling
      preventOverlaps: true, // Prevent overlapping animations
    });

    setScrollTriggerInstance(scrollTrigger);

    return () => {
      scrollTrigger.kill();
    };
  }, [isInitialized]);

  const handleFeatureClick = (index: number) => {
    if (!scrollTriggerInstance || !isInitialized) return;

    const scrollDistance = window.innerHeight;
    const totalDistance = scrollDistance * (features.length - 1);
    const progress = index / (features.length - 1);
    
    const targetScroll = progress * totalDistance;

    window.scrollTo({
      top: scrollTriggerInstance.start + targetScroll,
      behavior: 'smooth'
    });
  };

  // Show loading state while images are being preloaded
  if (!isInitialized) {
    return (
      <div className="bg-[#E6F1F4] w-full h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="w-8 h-8 border-4 border-secondary border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading features...</p>
        </div>
      </div>
    );
  }

  return (
    <div ref={containerRef} className="bg-[#E6F1F4] w-full h-screen">
      <div ref={contentRef} className="w-full h-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-[5vh] h-full">
          <div className="text-center">
            <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900">
              App Features
            </h2>
            <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
              Discover what makes our app unique and powerful.
            </p>
          </div>
          
          <div className="flex flex-col md:flex-row items-center justify-between h-[75vh] mt-8">
            <div className="w-full md:w-1/2 relative h-full">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className={cn(
                    "w-full h-full absolute top-0 left-0 transition-opacity duration-500",
                    index === activeIndex ? "opacity-100" : "opacity-0 pointer-events-none"
                  )}
                >
                  <div className="relative w-full h-full">
                    <img
                      src={feature.image}
                      alt={feature.title}
                      className="w-full h-full object-contain"
                    />
                    <div
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2"
                      style={{
                        width: '80%',
                        height: '30px',
                        background: 'radial-gradient(ellipse at center, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 70%)',
                        filter: 'blur(5px)',
                        zIndex: -1,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="w-full md:w-1/2 md:pl-8">
              {features.map((feature, index) => (
                <div
                  key={index}
                  onClick={() => handleFeatureClick(index)}
                  className={cn(
                    "mb-4 overflow-hidden rounded-lg transition-all duration-300 cursor-pointer hover:opacity-90",
                    index === activeIndex
                      ? "bg-secondary text-white"
                      : "bg-white text-gray-900"
                  )}
                  style={{
                    height: index === activeIndex ? "auto" : "60px",
                    opacity: index === activeIndex ? 1 : 0.7,
                  }}
                >
                  <div className="flex items-center gap-3 p-3">
                    <div className="w-10 h-10 flex items-center justify-center rounded-full bg-white text-secondary">
                      {feature.icon}
                    </div>
                    <h3 className="text-xl font-bold">{feature.title}</h3>
                  </div>
                  <div 
                    className={cn(
                      "mt-4 px-3 pb-3",
                      index === activeIndex ? "opacity-100" : "opacity-0"
                    )}
                  >
                    <p className="mb-4 text-gray-700">{feature.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
