import React from "react";
import DeepLinkWrapper from "../helpers/deep-link-wrapper";

function Link() {
  const deepLink = window.location.pathname.substring(1);
  console.log(deepLink)
  return (
    <div className="w-full flex items-center justify-center min-h-screen bg-white">
      {deepLink && <DeepLinkWrapper link={deepLink} />}
    </div>
  );
}

export default Link;
