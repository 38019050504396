import React, { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";

const DownloadApp: React.FC = () => {
  useEffect(() => {
    const handleRedirect = () => {
      const iosAppStoreLink = "https://apps.apple.com/app/apple-store/id6560116116?pt=127150541&ct=main&mt=8";
      const androidPlayStoreLink = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.citymoshi.app";

      if (isIOS) {
        window.location.href = iosAppStoreLink;
      } else if (isAndroid) {
        window.location.href = androidPlayStoreLink;
      } else {
        window.location.href = "https://citymoshi.com";
      }

      setTimeout(() => {
        window.location.href = "https://citymoshi.com";
      }, 1000);
    };

    handleRedirect();
  }, []);

  return (
    <div className="w-full flex items-center justify-center min-h-screen bg-white">
      <img
        src="/images/logo/main-logo.png"
        alt="Citymoshi Logo"
        className="w-12 h-12 object-contain"
      />
    </div>
  );
};

export default DownloadApp;
