function Header() {
  return (
    <header className="hidden mx-auto w-full max-w-screen-xl px-2.5 md:px-20 md:flex items-center justify-between gap-5 h-28 z-50">
      <div className="flex items-center gap-1">
        <img
          src="/images/logo/main-logo.png"
          alt="Citymoshi Logo"
          className="w-12 h-12"
        />
        <h1 className="font-medium text-white font-inter text-3xl">
          CityMoshi
        </h1>
      </div>
      <ul className="m-0 p-0 hidden md:flex items-center gap-10">
        {/* <a href="#welcome">
          <li className="text-base text-white capitalize hover:underline cursor-pointer">
            Welcome
          </li>
        </a> */}
        <a href="#about">
          <li
            className="text-base text-white capitalize hover:underline cursor-pointer"
            id="#about"
          >
            About
          </li>
        </a>
        <a href="#features">
          <li
            className="text-base text-white capitalize hover:underline cursor-pointer"
            id="#features"
          >
            Highlights
          </li>
        </a>
        <a href="#events" onClick={(e) => {
          e.preventDefault();
          const eventsSection = document.getElementById('events');
          if (eventsSection) {
            const yOffset = -100;
            const y = eventsSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});

            setTimeout(() => {
              window.scrollBy({
                top: window.innerHeight,
                behavior: 'smooth'
              });
            }, 1000);
          }
        }}>
          <li
            className="text-base text-white hover:underline cursor-pointer"
            id="#events"
          >
            What{`'`}s On
          </li>
        </a>
      </ul>
      <div className="flex items-center gap-5">
        <a
          target="_blank"
          href="https://dashboard.citymoshi.com"
          className="bg-secondary text-white font-bold py-2 px-4 rounded outline-none flex items-center gap-1 hover:bg-secondary/80 cursor-pointer"
          type="button" rel="noreferrer"
        >
          Post Events
          <img
            src="/images/arrow-up-right.png"
            alt="Download"
            className="w-5 h-5"
          />
        </a>
      </div>
    </header>
  );
}

export default Header;
