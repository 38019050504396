// @ts-nocheck
import React, { useRef } from "react";
import {
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
  EffectCoverflow,
  EffectCube,
  EffectFlip,
  EffectCreative,
  EffectCards,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import { ChevronLeft, ChevronRight, MapPin } from "lucide-react";
import SwiperOptions from "swiper";
import SwiperCore from "swiper";
import { cn } from "../utils/utils";
import { API_URL } from "../constants";
import { Event } from "../types";
import { Link } from "react-router-dom";

SwiperCore.use([Navigation, Pagination, Autoplay]);

interface CustomSwiper extends SwiperOptions {
  swiper?: any;
}

function EventSlider() {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [events, setEvents] = React.useState<Event[]>([]);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sliderPerView = () => {
    if (windowWidth > 1024) {
      return 3;
    } else if (windowWidth > 768) {
      return 2;
    } else {
      return 1;
    }
  };

  const swiperRef = useRef<CustomSwiper>(null);
  const [activeIndex, setActiveIndex] = React.useState<number>(0);

  const handlePaginationClick = (index: number) => {
    const slidesPerView = sliderPerView();
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index * slidesPerView);
      setActiveIndex(index);
    }
  };

  React.useEffect(() => {
    const url = API_URL + "/website/today-events";
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setEvents(data);
      });
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return { day, month };
  };

  const renderUserIcons = (chat) => {
    const members = chat?.members || [];
    const icons = [];

    for (let i = 0; i < 3; i++) {
      if (i < members.length && members[i]?.picture) {
        icons.push(
          <img
            key={i}
            src={members[i].picture}
            alt={`user-${i + 1}`}
            className="w-5 h-5 rounded-full bg-white -ml-1 first:ml-0 border border-white"
          />
        );
      } else {
        icons.push(
          <img
            key={i}
            src="/images/user-1.png"
            alt={`placeholder-${i + 1}`}
            className="w-5 h-5 rounded-full bg-gray-300 -ml-1 first:ml-0 border border-white"
          />
        );
      }
    }

    return icons;
  };

  return (
    <div className="mx-auto w-full max-w-screen-xl mt-24" id="events">
      <div className="flex flex-col justify-center gap-2.5 mb-12">
        <h1 className="text-white capitalize font-extrabold text-4xl text-center font-lato">
          Your Day With CityMoshi
        </h1>
      </div>
      <Swiper
        ref={swiperRef as any}
        modules={[
          Navigation,
          Pagination,
          Autoplay,
          EffectFade,
          EffectCoverflow,
          EffectCube,
          EffectFlip,
          EffectCreative,
          EffectCards,
        ]}
        spaceBetween={40}
        slidesPerView={sliderPerView()}
        onSlideChange={(swiper: any) =>
          handlePaginationClick(swiper.activeIndex)
        }
        onSwiper={(swiper: any) => {
          if (swiperRef.current) {
            swiperRef.current.swiper = swiper;
          }
        }}
        style={{ zIndex: 0 }}
      >
        {events.map((event) => (
          <SwiperSlide key={event.id}>
            <div className="w-full h-[522px] rounded-3xl relative overflow-hidden z-0">
              <Link to={`/events/${event.id}`} className="w-full h-full cursor-pointer">
                <img
                  src={event.image}
                  alt={event.name}
                  className="w-full h-full rounded-3xl object-cover"
                />
                <div className="absolute bottom-0 left-0 w-full h-2/4 bg-[#00000066] rounded-t-3xl py-3.5">
                  <div className="relative p-5 flex flex-col gap-2.5">
                    <div className="absolute bg-secondary w-[75px] md:w-[87px] h-[75px] md:h-[87px] rounded-full -top-14 right-5">
                      <div className="p-4 flex flex-col items-center">
                        {event.startDate && (
                          <>
                            <span className="text-white font-medium md:font-bold font-lato text-2xl md:text-4xl leading-5 md:leading-7">
                              {formatDate(event.startDate).day}
                            </span>
                            <span className="text-white font-medium md:font-bold text-xl md:text-2xl font-lato leading-5 md:leading-7">
                              {formatDate(event.startDate).month}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1.5">
                      <h1 className="text-white font-medium text-sm lg:text-xl leading-6 font-lato line-clamp-2 lg:line-clamp-3">
                        {event.name}
                      </h1>
                      <div className="flex items-center gap-1.5">
                        <MapPin className="w-3.5 h-3.5 text-white" />
                        <p className="text-white text-xs md:text-[10px] font-lato">
                          {event.location.name}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col max-w-max gap-2">
                      {/* chats */}
                      <div className="flex items-center gap-1.5">
                        <div className="flex items-center">
                          {renderUserIcons(event.chat[0])}
                        </div>
                        <p className="text-white text-[10px] font-lato">
                          {/* +234 users in chat */}
                          {event.chat[0].members.length < 3
                            ? "1 user in chat"
                            : `${event.chat[0].members.length} users in chat`}
                        </p>
                      </div>
                      <div className="h-0.5 w-3/4 bg-white" />
                    </div>

                    {event.description.length > 150 ? (
                      <span className="text-xs md:text-sm text-white font-normal font-lato">
                        {event.description.substring(0, 100)}...
                      </span>
                    ) : (
                      <span className="text-xs md:text-sm text-white font-normal font-lato">
                        {event.description}
                      </span>
                    )}
                  </div>
                </div>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="flex flex-col items-center gap-5">
        <h3 className="hidden md:block text-grayLight text-center mt-10 text-sm font-lato max-w-xl leading-8">
          Welcome to CityMoshi, where event empowerment meets simplicity. Our
          innovative features are designed with you in mind, ensuring a seamless
          and secure event experience.
        </h3>

        {/* slider controls */}
        <div className="flex justify-center gap-5 mt-5">
          {/* mobile will have buttons */}
          <div className="flex md:hidden items-center gap-5">
            <button
              className={cn(
                "p-2 rounded-full",
                activeIndex === 0 ? "bg-white text-[#130F26]" : "bg-secondary"
              )}
              onClick={() => {
                if (swiperRef.current && swiperRef.current.swiper) {
                  swiperRef.current.swiper.slidePrev();
                }
              }}
            >
              <ChevronLeft className="w-3.5 h-3.5" />
            </button>
            <button
              className={cn(
                "p-2 rounded-full",
                activeIndex === events.length - 1
                  ? "bg-white text-[#130F26]"
                  : "bg-secondary"
              )}
              onClick={() => {
                if (swiperRef.current && swiperRef.current.swiper) {
                  swiperRef.current.swiper.slideNext();
                }
              }}
            >
              <ChevronRight className="w-3 h-3" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventSlider;
